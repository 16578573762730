<template>
  <div class="detailbox">
    <div style="width: 100%">
      <div class="detailtop">
        <div class="buttons">
          <el-button icon="el-icon-arrow-left" @click="goback">
            <!-- 返回 -->
            {{ $t("button_partner_back") }}
          </el-button>
          <el-button @click="deleteemail">
            <!-- 删除 -->
            {{ $t("label.delete") }}
          </el-button>
          <el-button
            @click="changenoread"
            :loading="isloading"
            v-if="type == '1'"
          >
            <!-- 标记为未读 -->
            {{ $t("label.emailobject.emaildetail.button.markasunread") }}
          </el-button>
        </div>
        <div class="detailtopright" v-if="isremindnum == true">
          <span>{{ this.shownum }}/{{ this.emaillist.length }}</span>
          <el-button icon="el-icon-arrow-up" @click="preemail"></el-button>
          <el-button icon="el-icon-arrow-down" @click="nextemail"></el-button>
        </div>
      </div>
      <div class="detailbot" v-loading="detailloading">
        <h2 v-if="emaildetail.detail">{{ emaildetail.detail.name }}</h2>
        <div class="inmsg">
          <div>
            <svg class="icon" aria-hidden="true">
              <use href="#icon-head"></use>
            </svg>
          </div>
          <div class="innumber">
            <h4 v-if="emaildetail.detail">
              {{ emaildetail.detail.syncmailname }}
            </h4>
            <div style="display: flex; align-items: center">
              <i class="el-icon-message"></i>
              <span v-if="emaildetail.detail">{{
                emaildetail.detail.fromaddress
              }}</span>
            </div>
          </div>
        </div>
        <div class="tonumber">
          <h5
            v-if="emaildetail.detail"
            style="max-width: 950px; overflow: hidden"
          >
            <!--发送给 -->
            {{ $t("label.emailobject.emaildetail.title.toaddress") }}
            {{ emaildetail.detail.toaddress }}
          </h5>
          <h5
            v-if="emaildetail.detail && emaildetail.detail.ccaddress != ''"
            style="max-width: 950px; overflow: hidden"
          >
            <!-- 抄送给 -->
            {{ $t("label.emailobject.emaildetail.title.ccaddress") }}
            {{ emaildetail.detail.ccaddress }}
          </h5>
        </div>
        <div
          v-if="emaildetail.detail"
          v-html="emaildetail.detail.htmlbody"
          style="padding: 0 10px; max-width: 950px; overflow: hidden"
        ></div>
        <div v-if="emaildetail.attachment" class="attachmentlist">
          <ul>
            <li
              v-for="(item, index) in emaildetail.attachment"
              :key="index"
              :title="item.fileName"
              @mouseenter="enterstyle(item.fileContentId)"
              @mouseleave="leavestyle(item.fileContentId)"
            >
              <div class="fileicon">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-file1"></use>
                </svg>
              </div>
              <span>{{ item.fileName }}</span>
              <div
                :class="[
                  item.ischangeclass == false ? 'uploadicon' : 'uploadicon1',
                ]"
              >
                <div style="display: flex; align-items: center">
                  <div
                    style="width: 24px; height: 26px; margin-left: 5px"
                    @click="downloadfile(item)"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="width: 100%; height: 100%"
                    >
                      <use href="#icon-xiazai1"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="buttons-bot">
        <el-button @click="forwardemail">
          <!-- 转发 -->
          {{ $t("label.emailobject.emaildetail.button.forward") }}
        </el-button>
        <el-button @click="replyallemail">
          <!-- 回复全部 -->
          {{ $t("label.emailobject.emaildetail.button.replyall") }}
        </el-button>
        <el-button type="primary" @click="replyemail">
          <!-- 回复 -->
          {{ $t("label.emailobject.emaildetail.button.reply") }}
        </el-button>
      </div>
      <!-- 删除弹窗 -->
      <div>
        <el-dialog
          :title="$t('label.prompt')"
          :visible.sync="deletestatus"
          width="30%"
        >
          <span>
            <!-- 确认要删除吗 -->
            {{ $t("label.temp.del.conf") }}
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="deletestatus = false">
              <!-- 取 消 -->
              {{ $t("label.cancel") }}
            </el-button>
            <el-button
              type="primary"
              @click="deletefun"
              :loading="submitloading"
            >
              <!-- 确 定 -->
              {{ $t("label.confirm") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <div class="detailright">
      <div class="contacts">
        <div>
          <div class="contactsnum">
            {{ othermsg.length }}
            <!-- 个联系人 -->
            {{ $t("label.emailobject.contacts") }}
          </div>
          <div class="othermsg">
            <div>
              <ul>
                <li
                  v-for="(item, index) in othermsg"
                  @click="tablist(item.name)"
                  :key="index"
                  :class="[item.tabstyle == true ? 'tabstyle' : '']"
                >
                  <div class="otherlist">
                    {{ item.label }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in othermsg"
        :key="index"
        v-show="item.tabstyle == true"
        class="msgdetail"
      >
        <div>
          <svg class="icon" aria-hidden="true">
            <use href="#icon-head"></use>
          </svg>
        </div>
        <div class="messageicon">
          <h5>{{ item.name }}</h5>
          <div class="emailurl">
            <i class="el-icon-message"></i>
            <span>{{ item.email }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="addfor">
        <div>添加到潜在客户</div>
        <div>添加到联系人</div>
      </div> -->
      <!-- 删除弹窗 -->
      <div class="relavent">
        <div class="relate-title">
          <span>
            <!-- 相关项 -->
            {{ $t("label.relateobj") }}
          </span>
          <i
            class="el-icon-close"
            style="font-size: 18px"
            v-if="isRelation"
            @click="goRelate"
          ></i>
        </div>
        <div v-if="!isRelation">
          <relavent-select></relavent-select>
        </div>
        <div v-if="isRelation">
          <h4>{{ relateidccname }}</h4>
          <ul>
            <li
              v-for="(item, index) in relatedDetails"
              :key="index"
              class="relate-detail-list"
            >
              <span>{{ item.fieldLabel }}</span>
              <h4>{{ item.fieldValue }}</h4>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <!-- 提示 -->
        <el-dialog
          :title="$t('label.prompt')"
          :visible.sync="deletestatus"
          width="30%"
        >
          <span>
            <!-- 确认要删除吗 -->
            {{ $t("label.temp.del.conf") }}
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="deletestatus = false">
              <!-- 取 消 -->
              {{ $t("label.cancel") }}
            </el-button>
            <el-button
              type="primary"
              @click="deletefun"
              :loading="submitloading"
            >
              <!-- 确 定 -->
              {{ $t("label.confirm") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <file-preview
      :show="showPreview"
      :showUrl="previewUrl"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
  </div>
</template>


<script>
import relaventSelect from "@/components/Email/relaventSelect";
import * as Emailobject from "./api.js";
import filePreview from "@/components/FileView/filePreview";
import * as fileApi from "./api.js";
export default {
  data() {
    return {
      emaildetail: {},
      detailloading: true,
      showPreview: false,
      previewUrl: "",
      showPreviewData: {},
      deletestatus: false,
      submitloading: false,
      isloading: false,
      activeName: "second",
      emaillist: [],
      shownum: "",
      showlength: "",
      isremind: this.$route.query.NotificationCenter ? true : false,
      othermsg: [],
      isremindnum: true,
      relateid: this.relateid,
      relateidccname: "",
      relatedDetails: [],
      isRelation: true,
      type: this.$route.query.type,
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.id && this.isremind) {
          this.getemaildetail(this.$route.query.id, this.type);
        }
        this.changereadstatus(this.$route.query.id, "1");
      },
    },
    //更改邮件关联记录
    relateid(val) {
      if (val != "") {
        Emailobject.changerelateid({
          id: this.$route.query.id,
          relateid: val,
        })
          .then(() => {
            this.$message({
              showClose: true, //关联记录成功
              message: this.$i18n.t("vue_label_email_linkrecordsuccess"),
              type: "success",
            });
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.changereadstatus(this.$route.query.id, "1");
    //判断是否来自于通知中心,是则隐藏掉翻页与总数量
    if (this.isremind == true) {
      this.isremindnum = false;
      this.$Bus.$emit(
        "change-tab-style",
        this.$i18n.t("label.emailobject.list.inbox")
      );
    }
    if (this.$route.query.inboxlist) {
      this.emaillist = this.$route.query.inboxlist;
    }
    if (this.$route.query.id) {
      this.getemaildetail(this.$route.query.id, this.type);
    }
    if (this.$route.query.index) {
      this.shownum = parseInt(this.$route.query.index) + 1;
    }
    this.$Bus.$on("deliver-relateid",this.ebFn);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-relateid",this.ebFn);
  },
  components: {
    relaventSelect,
    filePreview,
  },
  methods: {
    ebFn (res) {
      this.relateid = res;
    },
    //显示相关项
    goRelate() {
      this.deleteemailretateid();
      this.isRelation = false;
    },
    //更改邮件状态
    changereadstatus(ids, isread) {
      Emailobject.changeread({
        ids: ids,
        isread: isread,
      })
        .then(() => {
          this.$Bus.$emit("get-email-noreadnum");
        })
        .catch(() => {});
    },
    //删除邮件关联记录
    deleteemailretateid() {
      Emailobject.deleterelateid({
        id: this.$route.query.id,
      })
        .then(() => {
          this.$message({
            showClose: true, //已取消关联记录
            message: this.$i18n.t("vue_label_email_linkrecordfail"),
            type: "success",
          });
        })
        .catch(() => {});
    },
    // 打开预览
    openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = {
        name: item.fileName,
        fileType: item.file_suffix.substring(1),
      };
      let arr = [];
      this.$refs.previewFile.handleBtn(arr);
      fileApi
        .viewFile({
          id: item.file_info_id,
        })
        .then((res) => {
          if (res.data.file_content_id) {
            this.previewUrl = res.data.previewurl;
          } else {
            this.previewUrl = "";
          }
        })
        .catch(() => {});
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
      this.previewUrl = "";
    },
    //转发邮件
    forwardemail() {
      this.$router.push({
        path: `/writeEmail`,
        query: {
          emailvalue: this.emaildetail,
        },
      });
      // 写邮件
      this.$Bus.$emit("change-tab-style", this.$i18n.t("label.ems.compose"));
    },
    //回复邮件
    replyemail() {
      this.$router.push({
        path: `/writeEmail`,
        query: {
          replyvalue: this.emaildetail,
        },
      });
      // 写邮件
      this.$Bus.$emit("change-tab-style", this.$i18n.t("label.ems.compose"));
    },
    //回复全部邮件
    replyallemail() {
      this.$router.push({
        path: `/writeEmail`,
        query: {
          replyvalues: this.emaildetail,
          alladdressee: this.othermsg,
        },
      });
      // 写邮件
      this.$Bus.$emit("change-tab-style", this.$i18n.t("label.ems.compose"));
    },
    //下载文件
    downloadfile(item) {
      const baseURL = this.$baseConfig.baseURL;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${item.file_info_id}`;
      link.click();
    },
    enterstyle(id) {
      this.emaildetail.attachment.forEach((item) => {
        if (item.fileContentId == id) {
          item.ischangeclass = true;
        }
      });
    },
    leavestyle(id) {
      this.emaildetail.attachment.forEach((item) => {
        if (item.fileContentId == id) {
          item.ischangeclass = false;
        }
      });
    },
    //切换联系人
    tablist(name) {
      this.othermsg.forEach((item) => {
        if (item.name == name) {
          item.tabstyle = true;
        } else {
          item.tabstyle = false;
        }
      });
    },
    //切换上封邮件
    preemail() {
      let copyarr = JSON.parse(JSON.stringify(this.emaillist));
      let reveserarr = copyarr.reverse();
      let emailindex = reveserarr.findIndex((item) => {
        return item.id == this.$route.query.id;
      });
      if (emailindex + 1 == reveserarr.length) {
        //已经是第一条了
      } else {
        let preobj = reveserarr.slice(emailindex + 1, emailindex + 2);
        this.$router.push({
          path: `/MailDetail`,
          query: {
            id: preobj[0].id,
          },
        });

        this.getemaildetail(this.$route.query.id, this.type);
        this.shownum--;
      }
    },
    //切换下封邮件
    nextemail() {
      let copyarr = JSON.parse(JSON.stringify(this.emaillist));
      let emailindex = copyarr.findIndex((item) => {
        return item.id == this.$route.query.id;
      });
      if (emailindex + 1 == copyarr.length) {
        //已经是最后一条了
      } else {
        let nextobj = copyarr.slice(emailindex + 1, emailindex + 2);
        this.$router.push({
          path: `/MailDetail`,
          query: {
            id: nextobj[0].id,
          },
        });

        this.getemaildetail(this.$route.query.id, this.type);
        this.shownum++;
      }
    },
    //返回邮件列表页
    goback() {
      this.$router.push({
        path: `/MailMain`,
        query: {
          type: this.type,
        },
      });
    },
    //更改为未读
    changenoread() {
      this.isloading = true;
      Emailobject.changeread({
        ids: this.$route.query.id,
        isread: "0",
      })
        .then((res) => {
          if (res.result == true) {
            this.isloading = false;
            //更改成功
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_notice_change_success"),
              type: "success",
            });
          }
          this.$Bus.$emit("get-email-noreadnum");
        })
        .catch(() => {});
    },
    //删除邮件改变状态
    deleteemail() {
      this.deletestatus = true;
    },
    //获取邮件详情页
    getemaildetail(id, incoming) {
      this.othermsg = [];
      this.detailloading = true;
      Emailobject.emaildetail({
        id: id,
        incoming: incoming,
      })
        .then((res) => {
          if (res.data != null && res.result == true) {
            this.emaildetail = res.data;
            this.detailloading = false;
            if (this.emaildetail.attachment) {
              this.emaildetail.attachment.forEach((item) => {
                this.$set(item, "ischangeclass", false);
              });
            }
            //收件箱拼接联系人
            if (this.type == "1") {
              let plussyncstr =
                this.emaildetail.detail.syncmail +
                "," +
                this.emaildetail.detail.toaddress;
              let ccsyncstr =
                this.emaildetail.detail.ccaddress != ""
                  ? this.emaildetail.detail.ccaddress
                  : "";
              let allsyncstr =
                ccsyncstr != "" ? plussyncstr + "," + ccsyncstr : plussyncstr;
              let stringResult = allsyncstr.split(",");
              let setarr = [];
              stringResult.forEach((item) => {
                let obj = {};
                obj.label = item.slice(0, 1);
                obj.name = item.match(/(\S*)@/)[1];
                obj.email = item;
                obj.tabstyle = false;
                setarr.push(obj);
              });
              this.othermsg = this.unique(setarr);
              this.othermsg[0].tabstyle = true;
            } else {
              //发件箱拼接联系人
              let plussyncstr =
                this.emaildetail.detail.fromaddress +
                ";" +
                this.emaildetail.detail.toaddress;
              let ccsyncstr =
                this.emaildetail.detail.ccaddress != ""
                  ? this.emaildetail.detail.ccaddress
                  : "";
              let allsyncstr =
                ccsyncstr != "" ? plussyncstr + ";" + ccsyncstr : plussyncstr;
              let stringResult = allsyncstr.split(";");
              let setarr = [];
              stringResult.forEach((item) => {
                let obj = {};
                obj.label = item.slice(0, 1);
                obj.name = item.match(/(\S*)@/)[1];
                obj.email = item;
                obj.tabstyle = false;
                setarr.push(obj);
              });
              this.othermsg = this.unique(setarr);
              this.othermsg[0].tabstyle = true;
            }
          }
          if (this.emaildetail.relate) {
            this.$Bus.$emit("component-Set-val", this.emaildetail.relate);
            if (this.emaildetail.relate.objid) {
              this.isRelation = true;
              this.relateidccname = this.emaildetail.relate.relateidccname;
              this.relatedDetails = this.emaildetail.relate.relatedata;
            } else {
              this.isRelation = false;
            }
          }
        })
        .catch(() => {});
    },
    //数组对象去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.email) && res.set(arr.email, 1));
    },
    //删除方法
    deletefun() {
      this.submitloading = true;
      Emailobject.deleteemail({
        id: this.$route.query.id,
      })
        .then((res) => {
          this.submitloading = false;
          this.deletestatus = false;
          if (res.result == true) {
            this.$message({
              showClose: true,
              // 删除成功
              message: this.$i18n.t("label.ems.delete.success"),
              type: "success",
            });
            this.goback();
          }
        })
        .catch(() => {
          this.goback();
        });
    },
  },
};
</script>



<style lang="scss" scoped>
::v-deep .dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .el-button {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-input__inner {
  height: 30px;
}
h2,
h4,
h5 {
  margin: 0;
}
.relate-detail-list {
  margin-top: 10px;
  h4 {
    font-weight: bold;
  }
}
.relate-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    cursor: pointer;
  }
}
.fileicon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.attachmentlist {
  margin-left: 10px;
  margin-top: 20px;
  ul {
    li {
      display: flex;
      position: relative;
      align-items: center;
      margin-bottom: 10px;
      background-color: #f3f2f2;
      overflow: hidden;
      width: 280px;
      padding: 10px;
      cursor: pointer;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: #006dcc;
      }
    }
  }
  .uploadicon {
    width: 280px;
    height: 100%;
    padding: 10px;
    background-color: #000000;
    position: absolute;
    left: 0;
    top: -50px;
    opacity: 0.8;
    -webkit-transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    z-index: 999;
  }
  .uploadicon1 {
    width: 280px;
    height: 100%;
    padding: 10px;
    background-color: #000000;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    -webkit-transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    z-index: 999;
  }
}
.relavent {
  padding: 20px 10px 0;
  min-width: 200px;
}
.detailbox {
  display: flex;
}
.detailtop {
  padding: 20px 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .buttons {
    display: flex;
    align-items: center;
  }
  .detailtopright {
    display: flex;
    align-items: center;
    ::v-deep .el-button {
      margin: 0;
      padding: 12px 10px;
    }
    span {
      margin-right: 10px;
    }
  }
}
.detailbot {
  margin: 10px 20px 0;
  height: 515px;
  overflow: auto;
  border: 1px solid #cbc6c6;
  h2 {
    padding: 15px 10px;
    background-color: #f3f2f2;
    font-size: 18px;
    color: #333333;
  }
  .inmsg {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    .innumber {
      margin-left: 10px;
      h4 {
        font-size: 14px;
        color: #333333;
      }
      i {
        color: #cbc6c6;
        margin-right: 5px;
      }
    }
  }
  .tonumber {
    margin-top: 10px;
    padding: 0 10px;
    margin-bottom: 20px;
  }
}
.buttons-bot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  ::v-deep .el-button--primary {
    background-color: #005fb2;
  }
}
.detailright {
  border-left: 1px solid #dcdcdc;
}
.contacts {
  padding: 20px 0 0 0;
  .contactsnum {
    padding-left: 10px;
    font-size: 14px;
    color: #006dcc;
    font-weight: bold;
    white-space: nowrap;
  }
  .othermsg {
    border-bottom: 2px solid #dcdcdc;
    max-width: 300px;
    overflow: auto;
    ul {
      display: flex;
      align-items: center;
      padding: 10px 10px 0 10px;
      margin: 0;
      .tabstyle {
        border-bottom: 2px solid #006dcc;
      }
      li {
        margin-right: 10px;
        cursor: pointer;
        .otherlist {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          justify-content: center;
          background-color: rgba(0, 109, 204, 0.29);
          color: #006dcc;
          border: 1px solid rgba(0, 109, 204, 0.29);
          border-radius: 50%;
        }
      }
    }
  }
}
.msgdetail {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  .messageicon {
    margin-left: 10px;
    h5 {
      font-size: 13px;
      color: #333333;
      font-weight: bold;
    }
    .emailurl {
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
  }
}
.addfor {
  padding: 10px;
  div {
    border: 1px solid #cbc6c6;
    padding: 10px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #080707;
    border-radius: 3px;
    cursor: pointer;
  }
}
</style>
